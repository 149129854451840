<template>
  <div class="page overflow-h center">
    <div class="page-bg pos-a">
      <img src="../assets/img/login-bg.jpg" />
    </div>
    <div class="login-wrap center" v-if="!reset">
      <div class="title">{{ $t("login.wangJiMiMa") }}</div>
      <div class="f-16">{{ $t("repass.tips") }}</div>
      <div class="mt-120">
        <div class="mt-25 flex border-b">
          <div class="lable mr-10">{{ $t("repass.zhuCeZhangHao") }}</div>
          <div class="flex-1">
            <input class="w-p100 h-p100" type="email" v-model="username" />
          </div>
        </div>
        <div class="c-ff000f f-16 mt-5">{{ $t("repass.emailTips") }}</div>
      </div>

      <div class="mt-45">
        <div class="btn" @click="login">{{ $t("repass.faSongYouJian") }}</div>
      </div>
    </div>

    <div class="login-wrap center" v-if="reset">
      <div class="title">{{ $t("reset.title") }}</div>
      <div class="f-16">{{ $t("reset.tips") }}</div>
      <div class="mt-120">
        <div class="mt-25 flex border-b">
          <div class="lable">{{ $t("reset.newpassword") }}</div>
          <div class="flex-1">
            <input
              class="w-p100 h-p100"
              type="password"
              v-model="re.password"
            />
          </div>
        </div>
        <div class="mt-25 flex border-b">
          <div class="lable">{{ $t("reset.repassword") }}</div>
          <div class="flex-1">
            <input
              class="w-p100 h-p100"
              type="password"
              v-model="re.repassword"
            />
          </div>
        </div>
      </div>

      <div class="mt-45">
        <div class="btn" @click="rp">{{ $t("reset.sure") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import langMinix from "../common/lang";
import { fpmail, rp } from "../api/index.js";
import { lang } from "../utils/utils";
export default {
  name: "Repass",
  components: {},
  data: () => {
    return {
      reset: false,
      username: "",
      password: "",
      lang: lang,
      redirect: undefined,
      re: {
        password: "",
        repassword: "",
      },
    };
  },

  created() {},
  mounted() {
    if (this.$route.query.from == "email") {
      this.reset = true;
      this.s = this.$route.query.s;
    }
  },
  minixs: [langMinix],
  methods: {
    login() {
      fpmail(this.username)
        .then((res) => {
          if (res && res.code === 0) {
            this.$router.go(-1);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async rp() {
      try {
        if (this.re.password != this.re.repassword) {
          this.$message("两次密码不一致");
          return;
        }
        let res = await rp(this.s, this.re.password);
        if (res?.code !== 0) {
          this.$message(res.msg);
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-bg {
  width: 1920px;
  z-index: -1;
  img {
    width: 100%;
  }
}

.logo-1 {
  width: 142px;
  height: 55px;
  background: url("../assets/img/logo-1.png");
}

.title {
  width: 703px;
  height: 99px;
  font-size: 58px;
  font-family: ABB;
  font-weight: bold;
  color: #000;
  line-height: 72px;
}

.mt-120 {
  margin-top: 12vh;
}

.login-wrap {
  margin-top: 271px;
  width: 580px;
  height: 317px;
  margin-left: 940px;
  z-index: 1;
  position: absolute;
}

.btn-login {
  width: 38px;
  height: 44px;
  // background: url("../assets/img/ico-user.png") no-repeat;
  background-size: 38px 44px;
}

.border-b {
  border-bottom: 1px solid #ddd;
}

.lable {
  color: #ff000f;
  font-size: 17px;
}

.c-ff000f {
  color: #ff000f;
}

.forget {
  color: #ff001a;
  font-size: 23px;
}

.btn {
  width: 357px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: var(--login-red);
  font-size: 26px;
  color: #fff;
  border-radius: 32px;
  margin-top: 140px;
}

.reg-tips {
  color: #474747;
  text-align: center;
}

@media screen and (max-width: 1680px) {
  .page-bg {
    width: 100vw;
  }

  .login-wrap {
    margin-left: 700px;
    margin-top: 201px;
  }
}
@media screen and (max-width: 1200px) {
  .page-bg {
    width: 1200px;
  }

  .login-wrap {
    margin-left: 580px;
    margin-top: 71px;
  }
}
</style>
